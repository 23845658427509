@import "../../colors";

$f1-red: rgb(237, 28, 48);
$mq_med: 768px;
$f1-red-dark: rgb(192, 8, 32);
$f1-orange: rgb(255, 165, 0);
$f1-grey: rgb(26, 37, 43);
$f1-grey-light: rgb(151, 151, 151);
$f1-blue-link: rgb(46, 130, 193);
$f1-blue: rgb(64, 164, 181);
$f1-teal: #3b9eaf;
$f1-white: white;
$f1-padding-base: 4px;
$f1-margin-base: 4px;
$f1-section-base: 4px;
$f1-section-base: 4px;
$f1-font-size-base: 4px;
.f1-weight {
  &-light {
    font-weight: 300;
  }
  &-regular {
    font-weight: 400;
  }
  &-medium {
    font-weight: 500;
  }
  &-semi-bold {
    font-weight: 600;
  }
  &-bold {
    font-weight: 700;
  }
}

.f1-letter-spacing {
  &-1 {
    letter-spacing: 1.7px;
  }
}

.f1-link-like {
  cursor: pointer;
  color: $f1-blue-link !important;
  text-decoration: none;
}

@function font-size-calculation($value) {
  @return 10 + $f1-font-size-base * $value;
}

@for $i from 0 through 7 {
  .f1-font-size-#{$i + 1} {
    font-size: font-size-calculation($i);
  }
  .f1-font-size-mobile-#{$i} {
    @media screen and (max-width: $mq_med) {
      font-size: font-size-calculation($i) !important;
    }
  }
}

@function padding-calculation($value) {
  @return $f1-padding-base * $value;
}

@for $i from 1 through 20 {
  .f1-padding-#{$i} {
    padding: padding-calculation($i);
  }
  .f1-padding-mobile-#{$i} {
    @media screen and (max-width: $mq_med) {
      padding: padding-calculation($i) !important;
    }
  }
}

@for $i from 1 through 10 {
  .f1-padding-top-#{$i} {
    padding-top: padding-calculation($i);
  }
  .f1-padding-mobile-top-#{$i} {
    @media screen and (max-width: $mq_med) {
      padding-top: padding-calculation($i) !important;
    }
  }
}

@function margin-calculation($value) {
  @return $f1-margin-base * $value;
}

@for $i from 1 through 6 {
  .f1-margin-#{$i} {
    margin: margin-calculation($i);
  }
}

@for $i from 1 through 10 {
  .f1-margin-top-#{$i} {
    margin-top: margin-calculation($i);
  }
  .f1-margin-mobile-top-#{$i} {
    @media screen and (max-width: $mq_med) {
      margin-top: margin-calculation($i) !important;
    }
  }
  .f1-margin-bottom-#{$i} {
    margin-bottom: margin-calculation($i);
  }
  .f1-margin-mobile-bottom-#{$i} {
    @media screen and (max-width: $mq_med) {
      margin-bottom: margin-calculation($i) !important;
    }
  }
}

@function section-calculation($value) {
  @return $f1-section-base * $value;
}

@for $i from 1 through 10 {
  .f1-section-#{$i} {
    padding-top: section-calculation($i);
    padding-bottom: section-calculation($i);
  }
}

/********************v
	GENERIC CLASSES
*********************/

.icon-4 {
  width: 60px;
  height: 60px;
}

.icon-3 {
  width: 22px;
  height: 22px;
}

.icon-2 {
  width: 18px;
  height: 18px;
}

.icon-1 {
  width: 8px;
  height: 8px;
}

.icon-stroke {
  &-white {
    stroke: white !important;
  }
  &-green {
    stroke: rgb(44, 199, 114) !important;
  }
  &-orange {
    stroke: $f1-orange !important;
  }
  &-red {
    stroke: $f1-red !important;
  }
  &-grey {
    stroke: $f1-grey !important;
  }
  &-blue {
    stroke: $f1-blue !important;
  }
  &-blue-link {
    stroke: $f1-blue-link !important;
  }
  &-teal {
    stroke: $f1-teal !important;
  }
  &-approved {
    stroke: $darker-green !important;
  }
}

.icon-fill {
  &-white {
    fill: white !important;
  }
  &-green {
    fill: rgb(44, 199, 114) !important;
  }
  &-orange {
    fill: $f1-orange !important;
  }
  &-red {
    fill: $f1-red !important;
  }
  &-grey {
    fill: $f1-grey !important;
  }
  &-blue {
    fill: $f1-blue !important;
  }
  &-blue-link {
    fill: $f1-blue-link !important;
  }
  &-teal {
    fill: $f1-teal !important;
  }
  &-approved {
    fill: $darker-green !important;
  }
}

.icon-color {
  &-white {
    color: white !important;
  }
  &-green {
    color: rgb(44, 199, 114) !important;
  }
  &-orange {
    color: $f1-orange !important;
  }
  &-red {
    color: $f1-red !important;
  }
  &-grey {
    color: $f1-grey !important;
  }
  &-blue {
    color: $f1-blue !important;
  }
  &-blue-link {
    color: $f1-blue-link !important;
  }
  &-teal {
    color: $f1-teal !important;
  }
  &-approved {
    color: $approved-color !important;
  }
}

.f1-markdown {
  blockquote {
    border-left: solid 1.5px #ddd;
    margin-left: 0px !important;
    padding-left: 40px !important;
    p {
      font-family: STIXGeneral;
      font-size: 21px;
      font-weight: normal;
      line-height: 1.25;
      color: #1a252b;
    }
  }
  ol {
    list-style: decimal inside none;
  }
  ul {
    list-style: disc inside none;
  }
}

.f1-bounce {
  -moz-animation: f1-bounce 2s 2s infinite;
  -o-animation: f1-bounce 2s 2s infinite;
  -webkit-animation: f1-bounce 2s 2s infinite;
  animation: f1-bounce 2s 2s infinite;
}

@keyframes f1-bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-12px);
  }
}
