@import '../../../src/colors';

.admin-page {
  .table-heading-style {
    font-weight: bold;
    font-size: 0.69rem;
    text-transform: uppercase;
    background-color: darken($page-bg-color, 5%);
    border: 1px solid darken($page-bg-color, 10%);
    border-bottom: 2px solid $link-color;
    color: $textSecondary;
    padding: 1.2rem !important;
  }

  .table-row-style {
    padding: 1.2rem !important;
  }
}
