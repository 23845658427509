@import "colors";

@mixin suggested-color {
  color: $suggested-color;
}

@mixin flagged-color {
  color: $flagged-color;
}

@mixin reported-color {
  color: $reported-color;
}

@mixin approved-color {
  color: $approved-color;
}

@mixin colored-dot {
  content: "•";
}

@mixin col-border {
  height: inherit;
  border-right-color: $page-bg-color;
  border-right-width: 3px;
  border-right-style: solid;
}

@mixin col-border-bottom {
  border-bottom-color: $page-bg-color;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

@mixin text-10 {
  //10.4px
  font-size: 0.65625rem;
}
@mixin text-11 {
  font-size: 0.69rem !important;
}
@mixin text-12 {
  font-size: 0.75rem !important;
}
@mixin text-13 {
  font-size: 0.813rem !important;
}
@mixin text-14 {
  font-size: 0.875rem !important;
}
@mixin text-15 {
  font-size: 0.938rem !important;
}
@mixin text-18 {
  font-size: 1.125rem !important;
}
@mixin text-20 {
  font-size: 1.25rem;
}

.text-10 {
  @include text-10;
}
.text-11 {
  @include text-11;
}
.text-12 {
  @include text-12;
}
.text-13 {
  @include text-13;
}
.text-14 {
  @include text-14;
}
.text-15 {
  @include text-15;
}
.text-18 {
  @include text-18;
}
.text-20 {
  @include text-20;
}
