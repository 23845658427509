@import "../../../../src/colors";

.groups {
  .case-explorer-heading {
    .badge {
      background-color: #007bff;
      margin-left: 5px;
    }
  }

  .table-heading-style {
    font-weight: bold;
    font-size: 0.69rem;
    text-transform: uppercase;
    background-color: $page-bg-color;
    border: 1px solid darken($page-bg-color, 10%);
    border-bottom: 2px solid $link-color;
    color: $textSecondary;
    padding: 1.2rem !important;
  }

  .table-row-style {
    padding: 1.2rem !important;
  }

  .case-explorer-pagination {
    justify-content: flex-start;
    color: $textSecondary;
    padding-right: 10px;
    font-size: 0.69rem;
    span {
      line-height: 2.5rem;
    }
  }

  .reaction {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    > div {
      border: 1px solid $textPrimary;
      border-radius: 50px;
      width: 21px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 9px;
    }
  }
}
