@import "../../colors";

.media-viewer-modal {
  .carousel-caption {
    position: relative;
    left: 0;
    top: 0;
    color: #9aa8b2;
  }

  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1;
    cursor: pointer;

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      width: 50px;
      height: 50px;
      background-color: $textSecondary;
      border-radius: 50%;
      background-size: 50% 50%;
    }
  }
}

.campaign-audience-modal {
  .left-nav {
    h6 {
      color: $dark-black;
      font-weight: bold;
    }

    .list-group-flush {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    .list-group-item-action {
      padding: 1.75rem 1rem;
      color: $dark-black;
      background-color: transparent;
      border-color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      border-bottom-width: 2px;

      &.no-border {
        border-bottom: none;
      }
      &:first-child {
        border-top-width: 2px;
      }

      &.active {
        background-color: #ffffff;
        color: $link-color;
        margin-left: -15px;
        margin-right: -15px;
        padding: 1.75rem 2rem;
        width: calc(100% + 30px);
        border-top-width: 2px;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .audience-header {
    height: 60px;
    padding: 1rem;
  }

  .audience-footer {
    margin-left: -15px;
    padding: 1rem 1rem 1rem 30px;
    box-shadow: 0 -0.25rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    position: relative;
  }

  .search-container {
    input {
      border-right: 0px;
    }
    input + .input-group-text {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left: 0px;
      background: white;
    }

    input.form-control:focus + .input-group-text {
      border-color: #80bdff;
    }
  }

  .audience-body {
    height: 550px;
    overflow-y: scroll;
    padding: 1rem;

    .form-check-input {
      margin-right: 15px;
      position: relative;
    }

    .table-heading-style {
      font-weight: bold;
      font-size: 0.69rem;
      text-transform: uppercase;
      border: 1px solid darken($light-white, 10%);
      color: $textSecondary;
      padding: 1rem !important;
    }

    .table-row-style {
      font-size: 0.875rem;
      border: 1px solid darken($light-white, 10%);
      color: $textPrimary;
      padding: 0.75rem !important;
    }

    .audience-section-heading {
      font-size: 15px;
      font-weight: 500;
      padding: 15px 0;

      .form-check-input {
        margin-left: 0;
      }
      &.sub {
        border-bottom: 2px solid $light-gray;
      }
    }

    .subspecialty-list {
      border-bottom: 2px solid $light-gray;
    }

    .select-all-container {
      border-bottom: 2px solid $lighter-gray;
      border-top: 2px solid $lighter-gray;
    }

    .subspecialty-item {
      &:last-child .audience-section-heading {
        border-bottom: none;
      }
    }

    .profession-expansion-buttons {
      button {
        margin-left: 20px;
        font-size: 0.875rem;
      }
    }

    table .bg-lighter-gray {
      border: 1px solid #dee2e6;
    }

    .subspecialty-header {
      background-color: $link-color;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 500;
      padding: 0.75rem 1rem;
    }

    .subspecialty-warning {
      border: 1px solid $link-color;
      color: $link-color;
      padding: 0.5rem 1rem;
      margin: 0.75rem 0;
    }

    .overview-section {
      font-weight: 500;
      color: $textSecondary;
      text-transform: uppercase;
      font-size: 0.69rem;
      padding: 0 0 0.5rem 0;
      border-bottom: solid 3px $link-color;
    }

    .overview-body {
      padding: 0.5rem 0 1.5rem 0;
      font-size: 0.875rem;
      color: $textPrimary;
    }
  }

  .search-suggestion-container {
    font-size: 0.875rem;
    background: white;
    z-index: 1;
  }

  .search-result-banner {
    border-bottom: 2px solid $lighter-gray;
  }
}

.modal-btn-spaced {
  .modal-footer {
    button {
      min-width: 100px;
    }
  }
}

.bordered-text {
  border-bottom: solid 2.5px #ccd3d8;
  padding-bottom: 1rem;
}

.no-outline {
  outline: none !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.notes-modal {
  tr {
    td {
      font-size: 0.875rem;

      &.date-column {
        font-size: 0.69rem;
        color: #6b778b;
      }
    }
  }
}

.tags-modal,
.case-explorer-promotion-modal {
  .added-tag-section {
    height: 280px;
  }

  .universal-manage-tags {
    min-height: 320px;

    .universal-remove-row {
      cursor: pointer;
      &:hover {
        background-color: $light-white;
      }
    }
  }

  .table-heading-style {
    font-weight: bold;
    font-size: 0.69rem;
    text-transform: uppercase;
    background-color: $light-white;
    border: 1px solid darken($light-white, 10%);
    color: $textSecondary;
    padding: 1rem !important;
  }

  .table-row-style {
    td {
      padding: 1rem !important;
    }

    .btn {
      line-height: 1.3;
    }

    &.active-promotion {
      background-color: lighten($light-blue, 5%);
    }
  }
}

.download-image-modal {
  .img-thumbnail {
    cursor: pointer;
  }
}
